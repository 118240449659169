/* eslint-disable */
/**
 * Keyboard Trapper
 *
 * This function will allow you to keep the focus within a specific area
 * @param {object} options - Pass through an object of options to handle how the keyboard trapper works.
 * @param {string} options.parent - The parent element of where you would like to limit the focus within, there should only be 1 parent.
 * @param {(boolean|string)} [options.setFocus = false] - Pass through a boolean if you would like autofocus to be set on the keyboard trapper area, or alternatively pass through the selector of an element - True: sets focus on parent, False: keeps focus on last focused object until tab action is made, String: the selector of the element you wish to set the focus to.
 * @param {(boolean|function)} [options.onDismiss = false] - Pass through a boolean or alternatively a function to handle the dismiss of the keyboard trapper - True: destroys the parent, False: only destroys the trap.
 * @param {boolean} [options.escapeKey = true] - Defines if keytrapper can be dismissed with the ESC key.
 * @param {boolean} [options.warnings = true] - Displays warning logs in console log relating to keyboard trapper.
 * @version 1.1.5
 * @author GashyDev
 * @copyright Discovery Design
 */

 export class keyboardTrapper {
  constructor(options) {
    // grabbed passed through object and merge default properties if they are not set
    this.parent = options.parent;
    this.setFocus =
      typeof options.setFocus === 'undefined' ? false : options.setFocus;
    this.onDismiss =
      typeof options.onDismiss === 'undefined' ? false : options.onDismiss;
    this.escapeKey =
      typeof options.escapeKey === 'undefined' ? true : options.escapeKey;
    this.warnings =
      typeof options.warnings === 'undefined' ? true : options.warnings;

    // store newly merged options object
    this.options = {
      parent: this.parent,
      setFocus: this.setFocus,
      onDismiss: this.onDismiss,
      escapeKey: this.escapeKey,
      warnings: this.warnings,
    };

    // check for errors and handle them here
    this.errorHandler(this.options);
  }

  // this initialises the keyboard trapper
  init() {
    // fetch all the focusable elements and declare first and last elements
    this.focusableElements = document
      .querySelector(this.options.parent)
      .querySelectorAll(this.focusableElementList());
    this.firstIndex = this.focusableElements[0];
    this.finalIndex = this.focusableElements[this.focusableElements.length - 1];

    // store original focal point reference - this is usually the trigger
    this.originalFocusedEl = document.activeElement;

    // handle start focus behaviour
    if (this.options.setFocus == true) {
      document.querySelector(this.options.parent).focus();
    } else if (typeof this.options.setFocus === 'string') {
      // check for errors and warnings
      this.errorHandler(this.options, 'check focus element');
      this.warningHandler('check focus element');

      // find first instance for selector just in case there are multiple instances
      document
        .querySelector(this.options.parent)
        .querySelectorAll(this.options.setFocus)[0]
        .focus();
    }

    // add event listener at body level to detect if focus has spilled outside of the keyboard trapper
    $('body').on('keydown.modalKeyTrapper', (e) => {
      // set up key codes
      let tabTrue = (e.keyCode || e.which) === 9;
      let shiftTrue = e.shiftKey;
      let escapeTrue = (e.keyCode || e.which) === 27;

      // get a reference of input
      let inputObj = e.target;

      // fire if escape key is pressed down
      if (escapeTrue && this.options.escapeKey) {
        if (inputObj.type == 'search') {
          if (inputObj.value == '') {
            this.dismiss();
          }
        } else {
          this.dismiss();
        }
      }

      // check to see if active element has slipped out of the trapped area
      if (tabTrue && shiftTrue) {
        if (
          !Array.from(this.focusableElements).includes(
            document.activeElement
          ) ||
          document.activeElement === this.firstIndex
        ) {
          e.preventDefault();
          this.finalIndex.focus();
        }
      } else if (tabTrue && !shiftTrue) {
        if (
          !Array.from(this.focusableElements).includes(
            document.activeElement
          ) ||
          document.activeElement === this.finalIndex
        ) {
          e.preventDefault();
          this.firstIndex.focus();
        }
      }
    });
  }

  // dismiss the keyboard trapper
  dismiss() {
    if (this.options.onDismiss == true) {
      $('body').off('keydown.modalKeyTrapper');
      this.originalFocusedEl.focus();
      document.querySelector(this.options.parent).remove();
    } else if (this.options.onDismiss == false) {
      $('body').off('keydown.modalKeyTrapper');
      this.originalFocusedEl.focus();
    } else {
      $('body').off('keydown.modalKeyTrapper');
      this.originalFocusedEl.focus();
      this.options.onDismiss();
    }
  }

  // this updates the reference list for if the elements in the parent change
  update() {
    this.focusableElements = document
      .querySelector(this.options.parent)
      .querySelectorAll(this.focusableElementList());
    this.firstIndex = this.focusableElements[0];
    this.finalIndex = this.focusableElements[this.focusableElements.length - 1];
  }

  // list of all the types of elements that can be focused on
  focusableElementList() {
    let focusableElementList = [
      'a[href]',
      'area[href]',
      'input:not([disabled])',
      'select:not([disabled])',
      'textarea:not([disabled])',
      'button:not([disabled])',
      'iframe',
      'object',
      'embed',
      '[tabindex]:not([tabindex="-1"])',
      '[contenteditable]',
    ];

    return focusableElementList.join(', ');
  }

  errorHandler(options, check = 'general') {
    if (check == 'general') {
      if (typeof options.parent === 'undefined') {
        throw new Error('Undefined parent selector.');
      }

      if (document.querySelectorAll(options.parent).length == 0) {
        throw new Error(`Parent selector doesn't exist.`);
      }

      if (document.querySelectorAll(options.parent).length > 1) {
        throw new Error(
          `More than one parent selector detected. Instances of the following selector "${
            options.parent
          }" detected: ${document.querySelectorAll(options.parent).length}`
        );
      }
    } else if (check == 'check focus element') {
      if (
        document
          .querySelector(options.parent)
          .querySelectorAll(options.setFocus).length == 0
      ) {
        throw new Error(
          `The following focus element doesn't exist: "${options.setFocus}".`
        );
      }
    }
  }

  warningHandler(check) {
    if (this.options.warnings == false) {
      return;
    }
    if (check == 'check focus element') {
      if (
        document
          .querySelector(this.options.parent)
          .querySelectorAll(this.options.setFocus).length > 1
      ) {
        console.warn(
          'More than one element was detected from this selector, try narrowing down the selector to get the desired element'
        );
      }
    }
    console.warn(
      'To dismiss key trapper warnings, pass "warnings: false" through object'
    );
  }
}
