import { keyboardTrapper } from '../autoload/keyboardTrapper';
import Swiper, { Navigation, Autoplay, A11y, Pagination, Mousewheel } from 'swiper';
Swiper.use([Navigation, Autoplay, A11y, Pagination, Mousewheel]);

require('jquery-ui/ui/widgets/datepicker');

export default {
  init() {
    // JavaScript to be fired on all pages

    const observerEl = jQuery('#scroll-observer');
    if (observerEl.length) {
      let observer = new IntersectionObserver(entries => {
        if (entries[0].boundingClientRect.y < 0) {
          jQuery('.header').addClass('scrolled');
        } else {
          jQuery('.header').removeClass('scrolled');
        }
      });
      observer.observe(observerEl[0]);
    }

    jQuery('input[name="area"]').click(function() {
      jQuery(this).parent().attr('style', 'background-color: blue;')
    })
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    let trapper = new keyboardTrapper({ parent: '.sidenav' });
    jQuery('.burger-menu, .close-menu').on('click', function () {
      let sidenav = jQuery('#mySidenav');
      sidenav.toggleClass('open');
      if (sidenav.hasClass('open')) {
        trapper.init();
      } else {
        trapper.dismiss();
      }
    });

    // check if any given review overflows, show a modal + button to view full content
    function reviewModalSetup(slider) {
      let reviewSlides = slider.slides;
      if (reviewSlides.length) {
        reviewSlides.forEach(function (reviewSlide) {
          let content = jQuery(reviewSlide).find('.content');
          if (content[0].scrollHeight > content[0].clientHeight) {
            content.addClass('truncated');
          }
        });
      }
    }

    if (jQuery('.swiper-reviews').length) {
      const reviews = new Swiper('.swiper-reviews', {
        loop: true,
        slidesPerView: 1,
        speed: 500,
        autoplay: {
          delay: 7000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-reviews-navigation .swiper-button-next',
          prevEl: '.swiper-reviews-navigation .swiper-button-prev',
        },
        a11y: {
          enabled: true,
        },
      });

      reviewModalSetup(reviews);

      jQuery(window).resize(function () {
        reviewModalSetup(reviews);
      });
    }

    if (jQuery('.swiper-gallery').length) {
      new Swiper('.swiper-gallery', {
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        a11y: {
          enabled: true,
        },
        mousewheel: {
          forceToAxis: true,
        },
        breakpoints: {
          1366: {
            spaceBetween: 70,
          },
          1199: {
            spaceBetween: 60,
          },
          991: {
            spaceBetween: 50,
          },
          767: {
            spaceBetween: 40,
          },
        },
      });
    }

    if (jQuery('.swiper-gallery-full').length) {
      new Swiper('.swiper-gallery-full', {
        loop: true,
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        a11y: {
          enabled: true,
        },
      });
    }

    if (jQuery('.swiper-posts').length) {
      new Swiper('.swiper-posts', {
        loop: true,
        speed: 500,
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.swiper-posts-navigation .swiper-button-next',
          prevEl: '.swiper-posts-navigation .swiper-button-prev',
        },
        a11y: {
          enabled: true,
        },
        breakpoints: {
          2150: {
            spaceBetween: 300,
            slidesPerView: 3,
          },
          1900: {
            spaceBetween: 200,
            slidesPerView: 3,
          },
          1650: {
            spaceBetween: 100,
            slidesPerView: 3,
          },
          1300: {
            spaceBetween: 80,
            slidesPerView: 3,
          },
          1050: {
            spaceBetween: 40,
            slidesPerView: 3,
          },
          850: {
            spaceBetween: 10,
            slidesPerView: 3,
          },
          600: {
            spaceBetween: 50,
            slidesPerView: 2,
          },
          500: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
        },
      });
    }

    // add title attribute to pagination links
    if (jQuery('a.page-numbers.next').length) {
      jQuery('a.page-numbers.next').attr('title', 'Next page');
    }
    if (jQuery('a.page-numbers.prev').length) {
      jQuery('a.page-numbers.prev').attr('title', 'Previous page');
    }

    // set up search datepickers
    if (jQuery('#search_start').length) {
      let today = new Date();
      let startDatepicker = jQuery('#search_start')
        .datepicker({
          dateFormat: 'dd/mm/yy',
          firstDay: 1,
          dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          minDate: today,
          onClose: function (dateText) {
            try {
              $.datepicker.parseDate('dd/mm/yy', dateText);
              jQuery('#room-search-submit').prop('disabled', false);
            } catch (e) {
                alert('Invalid date - your date must be in this format dd/mm/yyyy.');
                jQuery('#room-search-submit').prop('disabled', true);
            }
          },
        })

      if (!startDatepicker.val()) {
        startDatepicker.datepicker('setDate', today)
      }

      let future = today;
      future.setDate(today.getDate() + 1);

      let endDatepicker = jQuery('#search_end')
        .datepicker({
          dateFormat: 'dd/mm/yy',
          firstDay: 1,
          dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
          minDate: future,
          onClose: function (dateText) {
            try {
              $.datepicker.parseDate('dd/mm/yy', dateText);
              jQuery('#room-search-submit').prop('disabled', false);
            } catch (e) {
                alert('Invalid date - your date must be in this format dd/mm/yyyy.');
                jQuery('#room-search-submit').prop('disabled', true);
            }
          },
        })

        if (!endDatepicker.val()) {
          endDatepicker.datepicker('setDate', future)
        }

        startDatepicker.change(function() {
          var startDate = $(this).datepicker('getDate');
          var minEndDate = new Date(startDate);
          minEndDate.setDate(minEndDate.getDate() + 1);
          endDatepicker.datepicker('option', 'minDate', minEndDate);
        });
    }

  },
};
